<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="pl-1 primary--text">{{
          appExpConfigId != null ? formEditTitle : formNewTitle
        }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="tipoExpSelected"
                  :items="tiposExpHab"
                  label="Tipos de expedientes habilitados"
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="sectorSelected"
                  ref="sector-ingreso"
                  :items="sectores"
                  item-text="sectorNom"
                  item-value="sectorId"
                  label="Sector de ingreso"
                  outlined
                  clearable
                  dense
                  :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="entidadFacturanteSelected"
                  :items="entidadesFacturantes"
                  label="Entidad facturante"
                  item-text="value"
                  item-value="id"
                  return-object
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch
                  class="mt-0 align-center"
                  v-model="habilitaEnApp"
                  label="Habilitado en App"
                  outlined
                  dense
                >
                </v-switch>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="apps.length > 1 && habilitaEnApp"
                class="py-0"
              >
                <v-select
                  v-model="appSelected"
                  :items="apps"
                  label="Aplicación móvil"
                  item-text="appNombre"
                  item-value="appId"
                  outlined
                  dense
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-switch
                  class="mt-0 align-center"
                  v-model="habilitaEnSitioWeb"
                  label="Habilitado en sitio web"
                  dense
                  outlined
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" class="py-0 px-0">
                <v-subheader class="primary--text">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="primary--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      Información
                    </span>
                  </v-tooltip>
                  Se habilita por defecto la creación de expedientes vía
                  API</v-subheader
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="crud-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";

export default {
  name: "EditTiposDeExpedientes",
  props: {
    appExpConfigId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      rules: rules,
      calendarIcon: enums.icons.CALENDAR,
      isFormValid: false,
      apps: [],
      tipoExpSelected: null,
      tiposExpHab: [],
      appSelected: null,
      entidadFacturanteSelected: null,
      entidadesFacturantes: [],
      sectorSelected: null,
      sectores: [],
      formNewTitle: enums.titles.NUEVO_TIPOS_DE_EXPEDIENTES,
      formEditTitle: enums.titles.EDITAR_TIPOS_DE_EXPEDIENTES,
      habilitaEnSitioWeb: false,
      habilitaEnApp: false,
      infoIcon: enums.icons.SNB_INFO
    };
  },
  created() {
    if (this.appExpConfigId != null) {
      this.setTipoExp(this.appExpConfigId);
    }
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getTiposDeExpedientesById: "configuracionME/getTiposDeExpedientesById",
      fetchTiposDeExpedientesHabilitados:
        "configuracionME/fetchTiposDeExpedientesHabilitados",
      getSectores: "configuracionME/getSectores",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      saveTiposDeExpedientes: "configuracionME/saveTiposDeExpedientes",
      getAppsPublicadas: "configAppBenef/getAppsPublicadas",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const response = await this.getSectores();
      this.sectores = response;
      const res = await this.getAppsPublicadas();
      this.apps = res;
      const result = await this.fetchTiposDeExpedientesHabilitados();
      this.tiposExpHab = result;
      const res1 = await this.getEntidadesFacturantes();
      this.entidadesFacturantes = res1;
      this.entidadesFacturantes.id = res1.id;
      this.entidadesFacturantes.value = res1.value;
    },
    async setTipoExp() {
      const res = await this.getTiposDeExpedientesById(this.appExpConfigId);
      this.appSelected = res.appId;
      this.tipoExpSelected = res.tipoExpId;
      this.sectorSelected = res.sectorId;
      this.entidadFacturanteSelected = res.entFacId;
      this.habilitaEnSitioWeb = res.habilitaEnSitioWeb;
      this.habilitaEnApp = res.habilitaEnApp;
    },
    async saveEdit() {
      if (this.appExpConfigId === null || this.appExpConfigId === undefined) {
        const data = {
          appExpConfigId: this.appExpConfigId,
          appId: this.appSelected,
          tipoExpId: this.tipoExpSelected,
          entFacId: this.entidadFacturanteSelected.id,
          sectorId: this.sectorSelected,
          habilitaEnSitioWeb: this.habilitaEnSitioWeb,
          habilitaEnApp: this.habilitaEnApp
        };
        const res = await this.saveTiposDeExpedientes(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        }
        this.closeModal();
      } else {
        const data2 = {
          appExpConfigId: this.appExpConfigId,
          appId: this.appSelected,
          tipoExpId: this.tipoExpSelected,
          entFacId: this.entidadFacturanteSelected.id
            ? this.entidadFacturanteSelected.id
            : this.entidadFacturanteSelected,
          sectorId: this.sectorSelected,
          habilitaEnSitioWeb: this.habilitaEnSitioWeb,
          habilitaEnApp: this.habilitaEnApp
        };
        const res = await this.saveTiposDeExpedientes(data2);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        }
        this.closeModal();
      }
    },
    closeModal() {
      this.editTipoExpDialog = false;
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
