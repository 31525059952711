<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      class="elevation-1"
      :items="tiposDeExpedientes"
      :loading="loading"
      :search="search"
    >
      <template v-slot:[`item.habilitaEnSitioWeb`]="{ item }">
        <v-icon v-if="item.habilitaEnSitioWeb" color="primary" small> {{ check }} </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right"> Nuevo </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              @click="openModal(item.appExpConfigId, item.appCode)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              @click="deleteTipoExpediente(item.appExpConfigId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="editTipoExpDialog"
      v-model="editTipoExpDialog"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditTiposDeExpedientes
        :appExpConfigId="appExpConfigId"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import EditTiposDeExpedientes from "@/components/modules/appBenef/configuracion/EditTiposDeExpedientes.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "TiposDeExpedientesApp",
  components: {
    PageHeader,
    EditTiposDeExpedientes,
    DeleteDialog,
    GoBackBtn,
  },
  data() {
    return {
      searchIcon: enums.icons.SEARCH,
      search: "",
      headers: [
        { text: "Tipo", value: "tipoExp" },
        { text: "Sector", value: "sector" },
        {
          text: "Entidad Facturante",
          align: "start",
          value: "entFacNom",
        },
        { text: "Habilitado en app", value: "appNom" },
        { text: "Habilitado en sitio web", align: "center", value: "habilitaEnSitioWeb", sortable: false },
        { text: "Acciones", value: "actions", align: "end", sortable: false },
      ],
      title: enums.titles.TIPOS_DE_EXPEDIENTES,
      loading: false,
      tiposDeExpedientes: [],
      titleDelete: "¿Desea eliminarlo?",
      editTipoExpDialog: false,
      routeToGo: "ConfiguracionMesaEntrada",
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      showDeleteModal: false,
      idToDelete: null,
      allowedActions: null,
      appCode: "",
      canEdit: false,
      canDelete: false,
      canCreate: false,
      check: enums.icons.CHECK_OUTLINE,
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadTiposExpedientes();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposDeExpedientes: "configuracionME/getTiposDeExpedientes",
      deleteTiposDeExpedientes: "configuracionME/deleteTiposDeExpedientes",
      setAlert: "user/setAlert",
    }),
    async loadTiposExpedientes() {
      const response = await this.getTiposDeExpedientes();
      this.tiposDeExpedientes = response;
    },
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TIPOS_DE_EXPEDIENTES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_TIPOS_DE_EXPEDIENTES:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_TIPOS_DE_EXPEDIENTES:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    deleteTipoExpediente(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTiposDeExpedientes({
        appExpConfigId: this.idToDelete,
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTiposExpedientes();
      }
    },
    openModal(appExpConfigId, appCode) {
      this.editTipoExpDialog = true;
      this.appExpConfigId = appExpConfigId;
      this.appCode = appCode;
    },
    closeModal() {
      this.editTipoExpDialog = false;
    },
    closeAndReload() {
      this.editTipoExpDialog = false;
      this.loadTiposExpedientes();
    },
  },
};
</script>

<style scoped>
</style>

